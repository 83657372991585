<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="margin-bottom-10 top">
        <div>
          <Button type="primary" :loading="loading" style="margin-right: 10px;" @click="changeKeyword">刷新缓存</Button>
        </div>

      </div>
      <div style="font-size: 14px;height: 500px;overflow-y: auto">
        <div v-for="(item,index) in data" :key="index">
          {{item}}
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:10,
      total:0,
      data: [],
      loading:false,
      keyword:'',
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      modalStatus:false,
      statusList:[],
      statusObj:{},
      addBtnLoading:false,
      type:'',
    }
  },
  components:{
  },
  mounted(){
  },
  watch: {
  },
  methods:{
    changeKeyword () {
      this.getList();
    },

    getList(){
      this.loading = true;
      let params = {
        site_id:-1,
        user_id:-1,
      };
      this.api.system.systemRefreshCache(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
        this.typesObj = res.types;
        this.service_typesObj = res.service_types;
        this.statusList = [];

      }).catch((err)=>{
        this.loading = false;
      })
    },

  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .search{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        margin-right: 10px;
        width: 50px;
        text-align: right;
      }
      >button{
        margin-right: 20px;
      }
    }
  }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 100px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
